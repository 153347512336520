@use 'styles/config' as *;
@use "sass:math";

.megaButton {
  position: relative;
  margin-left: -$gutter;
  margin-bottom: 5rem + $gutter;
  width: 35rem;
  max-width: calc(100% - 1rem);
  background: brand();

  @media (min-width: $min-600) {
    margin-left: 0;
  }

  @media (min-width: $min-960) {
    width: 50rem;
    max-width: calc(100% - 2rem);
  }

  &__content {
    display: flex;
    align-items: center;
    padding: 3.5rem 3rem;

    font-size: responsive(1.6rem, 1.8rem);
    font-weight: 600;

    @media (min-width: $min-960) {
      padding: 4rem 4rem;
    }

    @media (min-width: $min-1280) {
      padding: 5rem 5rem 5rem 7.6rem;
    }

    .hasNumber & {
      font-size: responsive(1.3rem, 1.5rem);
    }
  }

  &__text {
    flex: 1 1 auto;
    display: flex;
    justify-content: space-between;

    p {
      @include reset-paragraph;
    }

    .hasNumber & {
      text-transform: uppercase;
    }
  }

  &__number {
    font-size: math.div(48, 15) * 1em;
    letter-spacing: math.div(-0.5, 48) * 1em;
    line-height: 1;
    margin-right: 2rem;
  }

  &__icon {
    transform: rotate(90deg);

    svg {
      width: 1.1rem;
      height: 1.125em;
    }
  }

  &__caption {
    padding: 3rem;

    line-height: math.div(24, 15);
    letter-spacing: math.div(0.83, 15) * 1em;
    font-size: responsive(1.4rem, 1.5rem);
    border-top: 1px solid rgba(#000, 0.05);

    @media (min-width: $min-960) {
      padding: 3.5rem 4rem;
    }

    @media (min-width: $min-960) {
      padding: 3.5rem 7.6rem 3.5rem;
    }

    .noContent & {
      padding: 3.5rem 3rem;

      @media (min-width: $min-960) {
        padding: 4rem 4rem;
      }

      @media (min-width: $min-1280) {
        padding: 5rem 5rem 5rem 7.6rem;
      }
    }
  }

  &__name {
    font-weight: 600;
    text-transform: uppercase;

    p {
      @include reset-paragraph;
    }
  }

  &__title {
  }

  &__button {
    position: absolute;
    bottom: 0;
    right: -5rem;
    transform: translateY(50%);
  }

  // brand color
  &.brand {
    color: brandFont();
    background: brand();
  }

  &.brand &__icon > svg {
    fill: brandFont(0.2);
  }

  &.brand &__caption {
    border-top-color: brandFont(0.075);
  }

  // white color
  &.white {
    color: $color-dark;
    background-color: #fff;
  }

  &.white &__icon > svg {
    fill: rgba(#000, 0.2);
  }

  &.white &__caption {
    border-top-color: rgba(#000, 0.05);
  }

  // dark color
  &.dark {
    color: #fff;
    background: brandDark();
  }

  &.dark &__icon > svg {
    fill: rgba(#fff, 0.25);
  }

  &.dark &__caption {
    border-top-color: rgba(#fff, 0.1);
  }
}
