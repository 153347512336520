@use 'styles/config' as *;

.banner {
  @include segment;
  position: relative;
  color: #fff;

  &__wrap {
    position: relative;
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: saturate(1.3);

    // &::before {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   right: 0;
    //   bottom: 0;
    //   background: rgba(#555, 0.3);
    //   mix-blend-mode: color-burn;
    //   z-index: 1;
    // }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(#555, 0.35);
      mix-blend-mode: darken;
      z-index: 2;
    }
  }

  &__container {
    @include container;
  }

  &__layout {
    @include segment;
    position: relative;
    z-index: 3;
    display: grid;
    align-items: center;
    row-gap: 3rem;
    column-gap: 3rem;
    min-height: min(90rem, 80vh);
    grid-template-rows: 1fr auto auto auto 1fr;
    grid-template-areas:
      '.'
      'header'
      'content'
      'footer'
      '.';

    .left & {
      @media (min-width: $min-840) {
        grid-template-columns: repeat(12, minmax(0, 1fr));
        grid-template-areas:
          '. . . . . . . . . . . . '
          '. header header header header header header header . . . .'
          '. .content content content content content content . . . . '
          '. . footer footer footer footer footer footer . . . . '
          '. . . . . . . . . . . . ';
      }

      @media (min-width: $min-1280) {
        grid-template-areas:
          '. . . . . . . . . . . . '
          '. header header header header header header . . . . .'
          '. . content content content content content . . . . . '
          '. . footer footer footer footer footer . . . . .'
          '. . . . . . . . . . . . ';
      }

      @media (min-width: $min-1080) {
        grid-template-areas:
          '. . . . . . . . . . . . '
          '. header header header header header . . . . . .'
          '. . content content content content . . . . . .'
          '. . footer footer footer footer . . . . . .'
          '. . . . . . . . . . . . ';
      }
    }

    .right & {
      @media (min-width: $min-840) {
        grid-template-columns: repeat(12, minmax(0, 1fr));
        grid-template-areas:
          '. . . . . . . . . . . . '
          '. . . . header header header header header header header .'
          '. . . . . content content content content content content .'
          '. . . . . footer footer footer footer footer footer .'
          '. . . . . . . . . . . . ';
      }

      @media (min-width: $min-1280) {
        grid-template-areas:
          '. . . . . . . . . . . . '
          '. . . . . header header header header header header .'
          '. . . . . . content content content content content .'
          '. . . . . . footer footer footer footer footer .'
          '. . . . . . . . . . . . ';
      }

      @media (min-width: $min-1080) {
        grid-template-areas:
          '. . . . . . . . . . . . '
          '. . . . . header header header header header . .'
          '. . . . . . content content content content . .'
          '. . . . . . footer footer footer footer . .'
          '. . . . . . . . . . . . ';
      }
    }
  }

  &__header {
    grid-area: header;
    max-width: 40rem;
  }

  &__content {
    grid-area: content;
    max-width: 40rem;
  }

  &__footer {
    grid-area: footer;
    padding-top: 3rem;
    margin-bottom: -3rem;
  }

  &__title {
    @include h2;
  }

  &__text {
    @include render-copy;
    color: #fff;

    p {
      color: inherit;
    }
  }
}
